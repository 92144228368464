import requests from './request';

import Qs from 'qs';

import { getCookie } from '@/utils/mycookie';
// 用户登录
export const userLogin = (d) => requests({ url: '/user/login', method: 'post', headers: { 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 刷新登录验证码
export const changeVerify = () => requests({ url: '/user/verify', method: 'get', headers: { 'x-codic': getCookie('x-myweb-key') } });
// 查询搜索关键词
export const getSg = (d) => requests({ url: 'https://suggestion.baidu.com/su?wd=' + d + '&action=opensearch', method: 'post', headers: {} });
// 添加城市
export const addCity = (d) => requests({ url: '/city/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加城市
export const getWeather = (d) => requests({ url: '/weather/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取热搜
export const getTrending = (d) => requests({ url: '/trend/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取搜索引擎列表
export const getEngines = (d) => requests({ url: '/enginetype/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 随机壁纸
export const randomWallpaper = (d) => requests({ url: '/wallpaper/random', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 随机壁纸
export const addFolder = (d) => requests({ url: '/enginetype/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加引擎
export const addEngine = (d) => requests({ url: '/engine/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除引擎分类
export const deleteEngineType = (d) => requests({ url: '/enginetype/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 更新引擎列表排序
export const updateEngines = (d) => requests({ url: '/engine/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 新建备忘录
export const addMemo = (d) => requests({ url: '/memo/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除备忘录
export const deleteMemos = (d) => requests({ url: '/memo/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改备忘录
export const updateMemo = (d) => requests({ url: '/memo/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询备忘录
export const listMemos = (d) => requests({ url: '/memo/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 标记备忘录
export const checkMemo = (d) => requests({ url: '/memo/check', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取七牛云上传卡密
export const getQiNiuToken = (d) => requests({ url: '/qiniu/token', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
