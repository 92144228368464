<template>
    <div class="codics-simple-top-box-body">
        <MyDialog v-model="addMemoState" :width="'700px'" :title="'添加备忘录'">
            <el-select popper-class="iconfont" style="margin-top: 15px;"
                class="codics-simple-top-box-dialog-type-pick iconfont" v-model="memoInfo.type"
                placeholder="请选择备忘录类型..." clearable>
                <el-option v-for="(item, index) in ['不重要不紧急', '重要不紧急', '紧急不重要', '紧急重要']" :key="index" :label="item"
                    :value="index">
                    <span class="iconfont" v-html="item"></span>
                </el-option>
            </el-select>
            <el-date-picker class="codics-simple-top-box-dialog-type-pick2" v-model="memoInfo.stime" type="date"
                placeholder="选择备忘录日期...">
            </el-date-picker>
            <el-input v-model="memoInfo.content" style="margin-top: 15px;" type="textarea" ref="memoContent"
                :autosize="{ minRows: 4, maxRows: 6 }" class="codics-simple-top-box-dialog-input"
                placeholder="请输入备忘录内容..." clearable>
            </el-input>
            <el-upload action="cdn.bosscaigou.com" accept="image/jpeg,image/png,image/jpg,image/webp,image/svg+xml"
                :http-request="(params) => uploadFile(params)" :limit="1" :show-file-list="false">
                <div class="codics-simple-top-box-dialog-input-img-add iconfont">&#xe626;</div>
            </el-upload>
            <div style="height: 100px;width: 100%;position: relative;float: left;">
                <div class="codics-simple-home-dialog-btn" @click="addMemoFuc">添加备忘录</div>
            </div>
        </MyDialog>
        <div class="codics-simple-top-box-line">
            <div class="codics-simple-top-box-line-calendar">
                <div class="codics-simple-top-box-line-calendar-card">
                    <div class="codics-simple-top-box-line-calendar-view">
                        <div class="codics-simple-top-box-line-calendar-view-day">{{ pickDay }}</div>
                        <div class="iconfont codics-simple-top-box-line-calendar-view-month-icon codics-simple-top-box-line-calendar-view-month-last"
                            @click="pickPre(currentYear, currentMonth)" style="left: 0px;z-index: 1;">
                            &#xe842;</div>
                        <div class="codics-simple-top-box-line-calendar-view-pick-txt">{{ currentYear }} - {{
            currentMonth
        }}</div>
                        <div class="iconfont codics-simple-top-box-line-calendar-view-month-icon codics-simple-top-box-line-calendar-view-month-next"
                            @click="pickNext(currentYear, currentMonth)" style="right: 0px;">
                            &#xe844;</div>
                        <div class="codics-simple-top-box-line-calendar-view-tip-txt"
                            v-for="(item, index) in ['一', '二', '三', '四', '五', '六', '日']"
                            :style="{ 'color': index < 5 ? '#333' : '#FF0000' }" :key="item">{{ item }}</div>
                        <div class="codics-simple-top-box-line-calendar-view-num-txt" v-for="(item, index) in days"
                            @click="pick(item.day)"
                            :style="{ 'color': (item.day.getMonth() + 1 != currentMonth) ? '#BBB' : (item.day.getFullYear() == new Date().getFullYear() && item.day.getMonth() == new Date().getMonth() && item.day.getDate() == new Date().getDate() ? '#789AF7' : '#333'), '--caState': initColor(item, 0), '--cbState': initColor(item, 1), '--ccState': initColor(item, 2), '--cdState': initColor(item, 3) }"
                            :key="index">{{ item.day.getDate() }}
                        </div>
                    </div>
                    <div class="codics-simple-top-box-line-calendar-memo-box">
                        <div class="codics-simple-top-box-line-calendar-memo-load" v-show="memoLoad"></div>
                        <!-- @contextmenu="(e) => handleContextMenu(e, item, index)" -->
                        <div class="codics-simple-top-box-line-calendar-memo" v-for="(item, index) in memoData"
                            :key="index">
                            <span class='codics-simple-top-box-line-calendar-memo-txt'
                                style='position:relative;float:left;min-width: 100%;'>
                                <span :style="{ 'color': ['green', 'grey', 'yellow', 'red'][item.type] }">{{ ['☐', '☑',
            '☒'][item.state] }}&nbsp;</span>
                                <span v-html="item.content"></span>
                            </span>
                            <div class='codics-simple-top-box-line-calendar-memo-btn iconfont'
                                @click="checkMemoFuc(item.id, 1);">&#xe847;</div>
                            <div class='codics-simple-top-box-line-calendar-memo-btn iconfont'
                                @click="checkMemoFuc(item.id, 2);">&#xe84a;</div>
                            <div class='codics-simple-top-box-line-calendar-memo-btn iconfont'
                                @click="deleteMemosFuc(item);">
                                &#xe850;</div>
                            <div class='codics-simple-top-box-line-calendar-memo-btn iconfont'
                                @click="copyTxtFuc(item.content);">&#xe767;</div>
                        </div>
                        <!-- ☐☑☒ -->
                        <div class="codics-simple-top-box-line-calendar-memo-add" v-show="memoData.length != 0">
                            <div class="codics-simple-top-box-line-calendar-memo-add-box iconfont"
                                @click="addMemoState = true;"></div>
                        </div>
                        <el-image v-show="memoData.length == 0 && !memoLoad" @click="addMemoState = true;"
                            class="codics-simple-top-box-line-calendar-memo-empty"
                            src="https://cdn.bosscaigou.com/huayuan/img/%E6%9A%82%E6%97%A0%E4%BB%BB%E5%8A%A1.svg"
                            fit="fill"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iziToast from 'izitoast';
import MyDialog from '@/components/MyDialog';
import { addMemo, deleteMemos, updateMemo, listMemos, checkMemo, getQiNiuToken } from '@/api';
import { copyTxt, loadJs } from '@/utils/myutils';
import * as qiniu from 'qiniu-js';
export default {
    data() {
        return {
            memoInfo: {
                stime: new Date(),
                content: ''
            },
            memoLoad: false,
            addMemoState: false,
            currentDay: 1,
            currentMonth: 1,
            currentYear: 1970,
            currentWeek: 1,
            days: [],
            pickDay: null,
            currentMemo: [
                '测试'
            ],
            qiNiuInfo: {
                token: null
            },
            memoData: [],
            blurIndex: 0,
            memo_list: [],//时间格式必须为xxxx-xx-xx;,
            memoUnCheckData: {},
            memoColor: {
                'caColor': ['rgba(33, 199, 0, 0.58)', 'rgba(0,0,0,0)', '#2ec700'],
                'cbColor': ['rgba(102, 102, 102, 0.479)', 'rgba(0,0,0,0)', '#666'],
                'ccColor': ['rgba(255, 102, 0, 0.616)', 'rgba(0,0,0,0)', '#FF6600'],
                'cdColor': ['rgba(255, 0, 0, 0.527)', 'rgba(0,0,0,0)', '#da0808']
            }
        }
    },
    mounted() {
        this.getTokenFuc();
        this.initCalendar(null);
        this.listMemosFuc(this.memoInfo.stime);
    },
    components: {
        MyDialog
    },
    methods: {
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        initColor(item, idx) {
            let year = item.day.getFullYear();
            let month = item.day.getMonth() + 1;
            let day = item.day.getDate();
            let date = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
            if (this.memoUnCheckData[date] == null) {
                return 'rgba(0,0,0,0)';
            } else {
                switch (idx) {
                    case 0:
                        return this.memoColor['caColor'][this.memoUnCheckData[date].ca];
                    case 1:
                        return this.memoColor['cbColor'][this.memoUnCheckData[date].cb];
                    case 2:
                        return this.memoColor['ccColor'][this.memoUnCheckData[date].cc];
                    case 3:
                        return this.memoColor['cdColor'][this.memoUnCheckData[date].cd];
                }
            }
        },
        copyTxtFuc(txt) {
            iziToast.success({
                'message': '文本复制成功！'
            });
            copyTxt(txt);
        },
        async checkMemoFuc(id, state) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await checkMemo({ id: id, state: state });

            loading.close();

            if (data.code == 200) {

                this.memoData = data.data;

                this.memoUnCheckData = data.data1;

            } else {

                this.$message.error(data.message);

            }

        },
        handleContextMenu(event, item, index) {
            // 阻止默认的右键菜单显示
            event.preventDefault();
            console.log(item, index)

        },
        initCalendar: function (cur) {
            if (cur) {
                var date = new Date(cur);
            } else {
                var date = new Date();
                this.pickDay = date.getDate();
            }
            this.currentYear = date.getFullYear();
            this.currentMonth = date.getMonth() + 1;
            var str = this.formatDate(this.currentYear, this.currentMonth, 1);
            this.currentWeek = new Date(str).getDay() == 0 ? 7 : new Date(str).getDay();

            this.days.length = 0;//初始化数组
            for (var i = this.currentWeek - 1; i >= 0; i--) {
                var d = new Date(str);
                var memo_type = false;
                d.setDate(d.getDate() - i);
                for (var j = 0; j < this.memo_list.length; j++) {
                    var memoTime = new Date(this.memo_list[j]).getTime();
                    if (d.getTime() == memoTime) {
                        memo_type = true;
                    }
                }
                this.days.push({ day: d, memo_type: memo_type });
            }
            for (var i = 1; i <= 35 - this.currentWeek; i++) {
                var d = new Date(str);
                d.setDate(d.getDate() + i);
                var memo_type = false;
                for (var j = 0; j < this.memo_list.length; j++) {
                    var memoTime = new Date(this.memo_list[j]).getTime()
                    if (d.getTime() == memoTime) {
                        memo_type = true;
                    }
                }
                this.days.push({ day: d, memo_type: memo_type });
            }
        },
        pick: function (date) {
            this.pickDay = date.getDate();
            this.memoInfo.stime = date;
            this.listMemosFuc(date);
            this.initCalendar(this.formatDate(date.getFullYear(), date.getMonth() + 1, 1));
            var curPickDay = this.formatDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
            // 数据发送请求拿回即可，这里我就写死了,随便写两个样例
            if (curPickDay == "2018-05-11") {
                this.currentMemo = [{
                    cont: "setDate() 方法用于设置一个月的某一天。该方法什么都不返回"
                }, {
                    cont: "getTime() 方法可返回距 1970 年 1 月 1 日之间的毫秒数。"
                }]
            } else if (curPickDay == "2018-05-31") {
                this.currentMemo = [{
                    cont: "getDay() 方法可返回表示星期的某一天的数字。返回值是 0（周日） 到 6（周六） 之间的一个整数"
                }, {
                    cont: "setDate(0); 上月最后一天；setDate(-1); 上月倒数第二天；setDate(dx) 参数dx为 上月最后一天的前后dx天"
                }]
            } else {
                this.currentMemo = null;
            }
        },
        pickPre: function (year, month) {
            // setDate(0); 上月最后一天
            // setDate(-1); 上月倒数第二天
            // setDate(dx) 参数dx为 上月最后一天的前后dx天
            var d = new Date(this.formatDate(year, month, 1));
            d.setDate(0);
            this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));

        },
        pickNext: function (year, month) {
            var d = new Date(this.formatDate(year, month, 1));
            d.setDate(35);
            this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        },
        // 返回 类似 2016-01-02 格式的字符串
        formatDate: function (year, month, day) {
            var y = year;
            var m = month;
            if (m < 10) m = "0" + m;
            var d = day;
            if (d < 10) d = "0" + d;
            return y + "-" + m + "-" + d
        },
        async listMemosFuc(time) {

            this.memoLoad = true;

            this.memoData = [];

            let data = await listMemos({ stime: time.getTime() });

            this.memoLoad = false;

            if (data.code == 200) {

                this.memoData = data.data;

                this.memoUnCheckData = data.data1;

            } else this.$message.error(data.message);

        },
        async addMemoFuc() {

            if (this.memoInfo.content == '') {

                iziToast.warning({
                    message: '备忘录内容不能为空！'
                });

                return;

            }

            if (this.memoInfo.stime == null) {

                iziToast.warning({
                    message: '备忘录绑定时间不能为空！'
                });

                return;

            }

            if ((this.memoInfo.type == null || this.memoInfo.type == '') && this.memoInfo.type != 0) {

                iziToast.warning({
                    message: '备忘录类型不能为空！'
                });

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addMemo({ ...this.memoInfo, state: 0, stime: new Date(this.memoInfo.stime).getTime() });

            loading.close();

            if (data.code == 200) {

                this.addMemoState = false;

                this.memoData = data.data;

                this.memoUnCheckData = data.data1;

            } else {

                this.$message.error(data.message);

            }
        },
        async deleteMemosFuc(item) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteMemos({ ids: [item.id], stime: new Date(item.stime).getTime() });

            loading.close();

            if (data.code == 200) {

                iziToast.success({
                    message: '备忘录删除成功！'
                });

                this.memoData = data.data1;

                this.memoUnCheckData = data.data2;

            } else {

                this.$message.error(data.message);

            }

        },
        insertTextAtCursor(text) {
            const input = this.$refs.memoContent; // 获取输入框的 DOM 元素
            if (document.selection) { // IE浏览器
                input.focus();
                const sel = document.selection.createRange();
                sel.text = text;
                sel.select();
            } else if (input.selectionStart || input.selectionStart === 0) { // 非IE浏览器
                const startPos = input.selectionStart;
                const endPos = input.selectionEnd;
                input.value = input.value.substring(0, startPos) + text + input.value.substring(endPos, input.value.length);
                input.selectionStart = startPos + text.length;
                input.selectionEnd = startPos + text.length;
            } else {
                input.value += text;
            }
        },
        // 上传方法
        uploadFile(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    loading.close();
                    _that.memoInfo.content += '<img src=\'https://cdn.bosscaigou.com' + '/' + res.key + '\'>';
                }
            }

            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            // 上传开始
            const subscription = observable.subscribe(observer)

        },
    }

}
</script>

<style>
.codics-simple-top-box-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

.codics-simple-top-box-line {
    height: 440px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-simple-top-box-line-calendar {
    height: 100%;
    width: 650px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-simple-top-box-line-calendar-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
    backdrop-filter: blur(1px);
    border-radius: 8px;
    border: 1px solid #EEE;
}

.codics-simple-top-box-line-calendar-view {
    height: 100%;
    width: 350px;
    position: relative;
    float: left;
    border-right: 1px solid #EEE;
    border-radius: 8px;
    backdrop-filter: blur(1px);
    box-sizing: border-box;
    padding: 0 15px;
}

.codics-simple-top-box-line-calendar-view-day {
    height: 65px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 85px;
    font-size: 45px;
    text-align: center;
    color: #789AF7;
}

.codics-simple-top-box-line-calendar-view-day::after {
    content: '日';
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 22px;
    left: 33px;
    right: 0;
    bottom: 0;
    font-size: 11px;
    color: #333;
}

.codics-simple-top-box-line-calendar-view-month-icon {
    height: 50px;
    width: 50px;
    position: relative;
    float: left;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 18px;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}

.codics-simple-top-box-line-calendar-view-pick-txt {
    height: 50px;
    width: calc(100% - 100px);
    position: relative;
    float: left;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #333;
}

.codics-simple-top-box-line-calendar-view-tip-txt {
    height: 45px;
    width: 14.285%;
    position: relative;
    float: left;
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    user-select: none;
    -webkit-user-drag: none;
}

.codics-simple-top-box-line-calendar-view-num-txt {
    height: 45px;
    width: 14.285%;
    position: relative;
    float: left;
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}

.codics-simple-top-box-line-calendar-view-num-txt::after {
    content: '';
    height: 24px;
    width: 24px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    box-sizing: border-box;
    border-top: 1.5px solid var(--caState);
    border-left: 1.5px solid var(--cbState);
    border-right: 1.5px solid var(--ccState);
    border-bottom: 1.5px solid var(--cdState);
}

.codics-simple-top-box-line-calendar-memo {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    line-height: 35px;
    box-sizing: border-box;
    padding: 0 5px;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    transition: .3s;
    overflow: hidden;
}

.codics-simple-top-box-line-calendar-memo-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
}

.codics-simple-top-box-line-calendar-memo:hover .codics-simple-top-box-line-calendar-memo-txt {
    -webkit-line-clamp: 3000 !important;
}

.codics-simple-top-box-line-calendar-memo:hover .codics-simple-top-box-line-calendar-memo-txt img {
    display: block !important;
}

.codics-simple-top-box-line-calendar-memo:hover .codics-simple-top-box-line-calendar-memo-btn {
    display: block !important;
}

.codics-simple-top-box-line-calendar-memo:hover,
.codics-simple-top-box-line-calendar-memo-add-box:hover {
    background: rgba(0, 0, 0, .12);
}

.codics-simple-top-box-line-calendar-memo-box {
    height: 100%;
    width: 280px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
    overflow-y: scroll;
}

.codics-simple-top-box-line-calendar-memo-add {
    height: 55px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 10px 0px;
}

.codics-simple-top-box-line-calendar-memo-add::after,
.codics-simple-top-box-line-calendar-memo-add::before {
    content: '';
    height: 1px;
    width: 30px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px dashed #CCC;
}

.codics-simple-top-box-line-calendar-memo-add::before {
    height: 14px;
    width: 1px;
    border-top: unset !important;
    border-right: 1px dashed #CCC;
}

.codics-simple-top-box-line-calendar-memo-add-box {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border: 1px dashed #CCC;
    line-height: 40px;
    text-align: center;
    font-size: 11px;
    color: #CCC;
    cursor: pointer;
    transition: .4s;
}

.codics-simple-top-box-dialog-type-pick {
    position: relative;
    float: left;
    margin-left: 15px;
}

.codics-simple-top-box-dialog-type-pick input {
    background-color: unset !important;
    border: 1px solid rgba(255, 255, 255, .18) !important;
    color: #FFF !important;
}


.codics-simple-top-box-dialog-input {
    width: calc(100% - 30px) !important;
    position: relative;
    float: left;
    margin-left: 15px;
}

.codics-simple-top-box-dialog-input textarea {
    height: 110px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgba(255, 255, 255, .18);
    color: #FFF !important;
    max-height: 110px !important;
}

.codics-simple-top-box-dialog-type-pick2 {
    position: relative;
    float: left;
    width: calc(100% - 261px) !important;
    margin-left: 15px;
    margin-top: 15px;
}

.codics-simple-top-box-dialog-type-pick2 input {
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgba(255, 255, 255, .18);
    color: #FFF !important;
}

.codics-simple-top-box-line-calendar-memo-empty {
    height: 150px;
    width: 150px;
    position: absolute !important;
    margin: auto;
    top: -30px;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    -webkit-user-drag: none;
    overflow: visible !important;
    cursor: pointer;
}

.codics-simple-top-box-line-calendar-memo-empty::after {
    content: '备忘录是空的，点我新建！';
    height: 40px;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 130px;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #777;
    cursor: pointer;
}

.codics-simple-top-box-line-calendar-memo-btn {
    height: 40px;
    width: 25%;
    position: relative;
    float: left;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    transition: .4s;
    display: none;
}

.codics-simple-top-box-line-calendar-memo-load {
    height: 40px;
    width: 48px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15% 15% 35% 35%;
    background: #CCC;
}

.codics-simple-top-box-line-calendar-memo-load::after {
    content: "";
    position: absolute;
    left: 45px;
    top: 8px;
    border: 4px solid #CCC;
    width: 16px;
    height: 20px;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
}

.codics-simple-top-box-line-calendar-memo-load::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    color: #CCC;
    top: -15px;
    left: 11px;
    -webkit-animation: animloader92 1s ease infinite;
    animation: animloader92 1s ease infinite;
}

@-webkit-keyframes animloader92 {
    0% {
        box-shadow: 2px 0px rgba(188, 188, 188, 0), 12px 0px rgba(188, 188, 188, 0.3), 20px 0px rgba(188, 188, 188, 0);
    }

    50% {
        box-shadow: 2px -5px rgba(188, 188, 188, 0.5), 12px -3px rgba(188, 188, 188, 0.5), 20px -2px rgba(188, 188, 188, 0.6);
    }

    100% {
        box-shadow: 2px -8px rgba(188, 188, 188, 0), 12px -5px rgba(188, 188, 188, 0), 20px -5px rgba(188, 188, 188, 0);
    }
}

@keyframes animloader92 {
    0% {
        box-shadow: 2px 0px rgba(188, 188, 188, 0), 12px 0px rgba(188, 188, 188, 0.3), 20px 0px rgba(188, 188, 188, 0);
    }

    50% {
        box-shadow: 2px -5px rgba(188, 188, 188, 0.5), 12px -3px rgba(188, 188, 188, 0.5), 20px -2px rgba(188, 188, 188, 0.6);
    }

    100% {
        box-shadow: 2px -8px rgba(188, 188, 188, 0), 12px -5px rgba(188, 188, 188, 0), 20px -5px rgba(188, 188, 188, 0);
    }
}

.codics-simple-top-box-line-calendar-memo-txt img {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    object-fit: contain;
    display: none;
}

.codics-simple-top-box-dialog-input-img-add {
    height: 66px;
    width: 65px;
    position: absolute;
    margin: auto;
    top: 150px;
    right: 16px;
    font-size: 50px;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    user-select: none;
    -webkit-user-drag: none;
    line-height: 65px;
    transition: .4s;
}
</style>